import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const image1 = require("../../../assets/images/blog_5/b5_1.webp");
const image2 = require("../../../assets/images/blog_5/b5_2.jpg");
const image3 = require("../../../assets/images/blog_5/b5_3.jpg");
const image4 = require("../../../assets/images/blog_5/b5_4.jpg");
const image5 = require("../../../assets/images/blog_5/b5_5.jpg");
const image6 = require("../../../assets/images/blog_5/b5_6.jpg");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Integrate OneLogin with Chatbot and automate user provisioning tasks"
        description="Learn how OneLogin customers are automating repetitive access management tasks using workativ assistant chatbot and streamline various employees IT requests such as Password Reset, User Provisioning, and Access Management via OneLogin IT Chatbot."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Automated OneLogin Just-In-Time User Provisioning
                            through Workativ Assistant
                          </h1>
                          <img
                            src={image1}
                            className="mb-4"
                            alt="Automated OneLogin Just-In-Time User Provisioning
                            through Workativ Assistant"
                          ></img>
                        </div>
                      </div>
                      <div className=" market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          Internal support teams receive an average of 492
                          tickets per month{" "}
                          <a href="https://d16cvnquvjw7pr.cloudfront.net/resources/whitepapers/Zendesk_WP_benchmark.pdf">
                            (Zendesk).
                          </a>
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          69% of internal support tickets are resolved in one
                          touch{" "}
                          <a href="https://d16cvnquvjw7pr.cloudfront.net/resources/whitepapers/Zendesk_WP_benchmark.pdf">
                            (Zendesk).
                          </a>
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          It takes an average of 24.2 hours to provide a first
                          response to an internal support ticket{" "}
                          <a href="https://d16cvnquvjw7pr.cloudfront.net/resources/whitepapers/Zendesk_WP_benchmark.pdf">
                            (Zendesk).
                          </a>
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Given that 69% of internal support tickets are solved
                          in a single communication, a first response time of 24
                          hours seems high. It suggests that internal help desk
                          teams may be too busy to respond to queries quickly.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          There are a few possible solutions to this issue:
                        </p>
                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            Increase the number of support employees per seat
                            served.
                          </li>
                          <li>
                            Create more aggressive response and resolution SLAs,
                            and use those metrics to measure employee
                            performance.
                          </li>
                          <li>
                            Adopt technologies that automate resolution for
                            basic, repetitive issues.
                          </li>
                        </ul>
                        <p class="font-section-normal-text line-height-2">
                          For example, an IT support chatbot that provides
                          automatic answers and guidance for common ticket types
                          reduces resolution times. It provides instant
                          solutions to common issues, preventing support
                          employees from getting overwhelmed by menial tasks
                          that distract their time and attention from more
                          complex problems. So, how can you go about building an
                          IT support chatbot?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Enter Workativ Assistant, an AI-powered no-code
                          platform for building contextual chatbots with
                          automated workflows for employee support and unlocking
                          various business apps. Workativ Assistant’s chatbots
                          are easy to add to your business’ communications hub
                          like{" "}
                          <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide">
                            Slack
                          </a>{" "}
                          or{" "}
                          <a href="https://workativ.com/conversational-ai-platform/microsoft-teams-vs-slack">
                            Microsoft Teams.
                          </a>{" "}
                          Employees can self-serve their IT issues on-the-go by
                          just having a quick chat with Workativ Assistant’s
                          chatbot without logging in and out of a convoluted
                          self-service portal. Sounds simple, right?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          If you’re using a cloud-based identity and access
                          management provider like OneLogin, you can easily
                          integrate it with Workativ Assistant and automate
                          tasks on it in just a few clicks.
                        </p>
                      </div>
                      <div>
                        <h2 class="font-section-sub-header-small">
                          IT support Automation for requests on Password Reset,
                          User Provisioning, and Access Management via OneLogin
                          Workflow Automations
                        </h2>
                        <img
                          loading="lazy"
                          src={image2}
                          className="mb-5"
                          alt="IT support Automation"
                        ></img>
                        <p class="font-section-normal-text line-height-2">
                          By integrating OneLogin and Workativ Assistant, you
                          streamline your workplace IT support to connect
                          employees, processes, and automations into a
                          transparent workflow. Let’s take a look at some of the
                          automations you get with Workativ Assistant and
                          OneLogin integration.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1. Hassle-free Access Management
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With OneLogin and Workativ Assistant,{" "}
                          <a href="https://workativ.com/conversational-ai-platform/it-helpdesk-chatbot">
                            employees can effortlessly reset their account
                            passwords
                          </a>{" "}
                          from the comfort of their Slack or Microsoft Teams
                          channel. Employees will just have to bring up Workativ
                          Assistant’s{" "}
                          <a href="https://workativ.com/conversational-ai-platform/onelogin-chatbot">
                            OneLogin Chatbot
                          </a>{" "}
                          on their Slack or Microsoft Teams channel, ask it to
                          reset the password of their account, provide required
                          details like email ID and new password and voila!
                          Their password gets reset and they’re able to access
                          their account in a jiff, preventing major disruption
                          to their productivity.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          2. Automated User Provisioning
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ Assistant, create Slack-based automated
                          OneLogin workflows that would allow help desk agents
                          to securely and easily add an employee, update
                          information about an employee, retrieve details about
                          an employee, or remove an employee on OneLogin—all
                          without leaving the chat app.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Bottom-line? Get rid of the tedious and time-consuming
                          traditional IT support model and switch to Workativ
                          Assistant’s OneLogin{" "}
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                            Chatbot for IT self-service&nbsp;
                          </a>
                          that’s faster and more secure.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          Intelligent IT Process Automation via OneLogin +
                          Workativ Assistant + 3rd Party Apps
                        </h5>

                        <h5 class="font-section-sub-header-small">
                          1. OneLogin and Azure Active Directory Integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          The Workativ Assistant + Azure AD + OneLogin
                          integration makes{" "}
                          <a href="https://workativ.com/use-cases/user-provisioning-automation">
                            user provisioning{" "}
                          </a>
                          and deprovisioning a fairly straightforward process.
                          Imagine an existing OneLogin user, let’s call him User
                          A. Using Workativ Assistant if we set up an automation
                          to push him into Azure AD after getting details about
                          him from OneLogin, Workativ Assistant naturally
                          creates a user named “User A” and incorporates him
                          into Azure AD.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Simply put, what goes into OneLogin, can get pushed up
                          into AAD.
                        </p>
                        <img
                          loading="lazy"
                          src={image3}
                          className="mb-5"
                          alt="OneLogin and Azure Active Directory Integration"
                        ></img>
                        <h5 class="font-section-sub-header-small">
                          2. OneLogin and Jira Integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          New developer joining your company? No worries. You
                          can set up a Workativ Assistant automation by
                          <a href="https://workativ.com/workflow-automation-platform/integrations/jira+onelogin">
                            {" "}
                            connecting your Jira and OneLogin accounts{" "}
                          </a>
                          such that, after retrieving details about the
                          developer from OneLogin, the details are used to
                          create a Jira issue giving the development team
                          heads-up to add the new teammate to the company’s Jira
                          workspace.
                        </p>
                        <img
                          loading="lazy"
                          src={image4}
                          className="mb-5"
                          alt="OneLogin and Jira Integration"
                        ></img>
                        <h5 class="font-section-sub-header-small">
                          3. OneLogin and Slack Integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Whenever there’s a new hire, help desk employees have
                          to create an email ID for the hire and convey the same
                          to the hire. This can be a bit frustrating for help
                          desk agents when the hiring pool is large and
                          frequent.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          By connecting your business’ OneLogin and Slack
                          accounts, you can set up an automation such that,
                          after creating an email ID for a new hire on OneLogin,
                          the same gets conveyed to the hire on their{" "}
                          <a href="https://workativ.com/conversational-ai-platform/slack-chatbot">
                            Slack
                          </a>{" "}
                          by Workativ Assistant’s chatbot.{" "}
                        </p>
                        <img
                          loading="lazy"
                          src={image5}
                          className="mb-5"
                          alt="OneLogin and Slack Integration"
                        ></img>
                        <h5 class="font-section-sub-header-small">
                          How you can easily create a OneLogin Chatbot using
                          Workativ Assistant
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Now that we’ve seen the endless possibilities of what
                          integrating Workativ Assistant and OneLogin and your
                          existing business apps can do for you, let’s see how
                          you can go about the same.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1. Workflow builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ assistant comes with easy-to-use no-code
                          workflow builder to help you build simple to complex
                          app-based automations for chatbot in minutes.
                          Integrate chatbot with apps, use pre-built workflows
                          from marketplace, approve requests, connect chatbot
                          with on-prem apps and much more.
                        </p>

                        <img
                          loading="lazy"
                          src={image5}
                          className="mb-5"
                          alt="Workflow builder"
                        ></img>
                        <h5 class="font-section-sub-header-small">
                          2. Chatbot Builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Our easy-to-use no-code{" "}
                          <a href="https://workativ.com/conversational-ai-platform/chatbot-builder">
                            chatbot builder
                          </a>{" "}
                          helps you build and manage simple to complex
                          conversations, FAQs, integrate app workflows, and
                          personalize your bot in minutes. Deliver autonomous
                          workplace support with purpose built intelligent
                          chatbots.
                        </p>
                        <img
                          loading="lazy"
                          src={image6}
                          className="mb-3"
                          alt="Chatbot Builder"
                        ></img>
                      </div>

                      <NocodeWrapper />
                      <h6 className="font-section-sub-header-small-bold">
                        What all these mean for you
                      </h6>

                      <div className="market_wrapper_page ">
                        <h6 className="font-section-sub-header-small ">
                          1. On-the-go conversational IT support that employees
                          would love
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform/it-helpdesk-chatbot">
                            Workativ Assistant’s conversational AI/chatbot
                          </a>{" "}
                          offers seamless 24×7 OneLogin related actions that
                          your employees/help desk agents can ask the chatbot to
                          do. Whether they are in the workplace or on the road,
                          employees can reset their password or do anything on
                          OneLogin with Workativ Assistant.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          2. Creating a Synergy between your existing Business
                          Apps
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Like the{" "}
                          <a href="https://workativ.com/workflow-automation-platform/integrations/onelogin+slack">
                            OneLogin Slack integration
                          </a>{" "}
                          that works synergistically, you can combine your other
                          business apps with OneLogin creating an automated
                          workflow that does much more with those apps in just a
                          single run.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          3. All from the comfort of your chat channel
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant’s chatbots integrate seamlessly
                          with your Slack or Microsoft Teams workspace so that
                          your employees don’t have to leave their chat hub to
                          self-serve an IT issue. This gets rid of the
                          frustration that comes with contacting the help desk
                          or logging in and out of the service desk portal
                          whenever an employee wants to create an IT support
                          ticket for a simple thing like a{" "}
                          <a href="https://workativ.com/use-cases/reset-password-automation">
                            password reset.
                          </a>
                        </p>

                        <h5 className="font-section-sub-header-small">
                          4. Improved IT support experience for employees
                        </h5>

                        <p class="font-section-normal-text line-height-2">
                          No more repetitive calls/tickets to the help desk.
                          With Workativ assistant’s chatbot, you make sure your
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                            employees don’t have to wait on endless calls
                          </a>{" "}
                          to get what they’re looking for which in turn leads to
                          your help desk agents’ productivity increase, letting
                          them concentrate on the complex tasks at hand that
                          require a human touch.
                        </p>

                        <p class="font-section-normal-text-medium line-height-2">
                          Liked what you read about Workativ Assistant? Try
                          Workativ Assistant today by signing up for a FREE
                          trial at{" "}
                          <a href="https://workativ.com/">workativ.com</a> and
                          experience the difference yourself.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free OneLogin Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
